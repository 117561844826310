import {HttpClient, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, scan, shareReplay} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {buildURLParams} from '@helpers/build-url-params';
import {isHttpProgressEvent, isHttpResponse} from '@helpers/is-http';
import {
  FileMultipartI,
  MediaMultipartParams,
  MediaParams,
  MediaPreSignedPayload,
  MediaUploadProgress,
} from '@models/cloud-media-uploader';
import {ResponseDto} from '@models/response';
@Injectable({
  providedIn: 'root',
})
export class MediaUploaderService {

  private readonly apiUrl = 'api/s3';

  constructor(
    private readonly http: TransferHttpService,
    private readonly httpClient: HttpClient,
  ) {
  }

  createPreSignedUrl(param: MediaParams): Observable<FileMultipartI> {
    return this.http.post<ResponseDto<FileMultipartI>>(`${this.apiUrl}/get-multipart-pre-signed-url`, param).pipe(
      map(res => res.data),
    );
  }

  getPreSignedUrl(payload: MediaPreSignedPayload): Observable<string> {
    const params = buildURLParams(payload);
    return this.http.get<ResponseDto<string>>(`${this.apiUrl}/get-pre-signed-get-url`, {params}).pipe(
      map(res => res.data),
    );
  }

  saveMediaInStorage(data: MediaMultipartParams, file: File): Observable<unknown> {
    const formData = this.formatAwsCreds(data, file);
    return this.httpClient.post<unknown>(data.action, formData);
  }

  saveMediaInStorageWithProgress(data: MediaMultipartParams, file: File): Observable<MediaUploadProgress> {
    const formData = this.formatAwsCreds(data, file);
    const initialState: MediaUploadProgress = {state: 'pending', progress: 0};
    return this.http.post<unknown>(data.action, formData, {
      reportProgress: true,
      observe: 'events' as any,
    }).pipe(
      scan(this.calculateState, initialState),
      shareReplay(1),
    );
  }

  formatAwsCreds(config: MediaMultipartParams, file: File): FormData {
    const formData = new FormData();
    formData.append('key', config.key);
    formData.append('acl', config.acl);
    formData.append('Content-Type', config.contentType);
    formData.append('X-Amz-Credential', config.credential);
    formData.append('X-Amz-Algorithm', config.algorithm);
    formData.append('X-Amz-Date', config.date);
    formData.append('Policy', config.policy);
    formData.append('X-Amz-Signature', config.signature);
    formData.append('file', file);
    return formData;
  }

  private calculateState = (upload: MediaUploadProgress, event: HttpEvent<unknown>): MediaUploadProgress => {
    if (isHttpProgressEvent(event)) {
      return {
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : upload.progress,
        state: 'in-progress',
      };
    }
    if (isHttpResponse(event)) {
      return {
        progress: 100,
        state: 'done',
      };
    }
    return upload;
  };
}
