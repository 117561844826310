export class PreviewImage {
  constructor(
    public readonly url: string,
    public readonly name: string,
  ) {
  }
}

export class ImagePreviewDialogConfig {
  constructor(
    public readonly  image?: PreviewImage | null,
    public readonly  hasBackdrop?: boolean,
    public readonly  backdropClass?: string,
    public readonly  panelClass?: string,
  ) {
  }
}

export const DEFAULT_IMAGE_PREVIEW_CONFIG: ImagePreviewDialogConfig = new ImagePreviewDialogConfig(
  null, true, 'dark-backdrop', 'tm-file-preview-dialog-panel',
);
