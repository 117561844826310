import {HttpResponse} from '@angular/common/http';
import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';

import {FeatureToggleService} from '@core/modules/feature-toggle';
import {QueueFileDownloaderService} from '@directives/file-downloader/services/queue-file-downloader.service';
import {DestroySubscription} from '@helpers/destroy-subscription';
import {BoatDetailsService} from '@services/boat-details/boat-details.service';

import {DownloadedFiles, DownloadUrl, QueueDownloadUrl} from '../models/download-files.model';
import {FileDownloaderService} from '../services/file-downloader.service';

@Directive({
  selector: '[appFileDownloader]',
})
export class FileDownloaderDirective extends DestroySubscription {

  @Input() appFileDownloader: DownloadedFiles;
  @Input() src: string;
  @Input() boatId: number;
  @Input() fleetId: number;
  @Input() filter: any;
  @Input() file: File;
  @Input() downloadUrl: DownloadUrl | QueueDownloadUrl;

  @Output() downloadStart = new EventEmitter<void>();

  private get boatIdParam(): number {
    const id = this.boatId;
    if (!id) {
      throw new Error('No boat id');
    }
    return id;
  }

  private get downloadFilter(): any {
    const filter = this.filter;
    if (!filter) {
      throw new Error('Filter is empty');
    }
    return filter;
  }

  private get filterFileId(): string | number {
    const filter = this.filter;
    if (!filter && !filter.id) {
      throw new Error('File id is empty');
    }
    return filter.id;
  }

  constructor(
    protected readonly boatDetailsService: BoatDetailsService,
    protected readonly fileDownloader: FileDownloaderService,
    protected readonly queueFileDownloaderService: QueueFileDownloaderService,
    protected readonly featureToggleService: FeatureToggleService,
  ) {
    super();
  }

  @HostListener('click', ['$event']) download(e: Event | null = null) {
    e?.stopPropagation();
    const downloadUrl = this.downloadUrl;
    if (downloadUrl) {
      if (Object.values(DownloadUrl).some(val => val === downloadUrl)) {
        this.downloadByUrl(downloadUrl as DownloadUrl);
        return;
      }
      const params = this.filter || {};
      this.downloadQueueFile(downloadUrl, params);
      return;
    }
    const file = this.appFileDownloader;
    if (file === 'other' && this.file) {
      this.downloadLocalFile();
      return;
    }
    if (file === 'other') {
      this.downloadFile(this.src, {});
      return;
    }
    if (file === 'expenseTemplate') {
      this.downloadExpenseTemplate();
      return;
    }
    if (file === 'expenseReport') {
      this.downloadExpenseReport();
      return;
    }
    if (file === 'crewList') {
      this.downloadCrewList();
      return;
    }
    if (file === 'boatUserList') {
      this.downloadBoatUserList();
      return;
    }
    if (file === 'expenseReceipts') {
      this.downloadExpenseReceipts();
      return;
    }
    if (file === 'myCv') {
      this.downloadMyCv();
      return;
    }
    if (file === 'crewCv') {
      this.downloadCrewCv();
      return;
    }
    if (file === 'cardStatementsPdfReport') {
      this.downloadCardStatementsPdf();
      return;
    }
    if (file === 'cardStatementsExcelReport') {
      this.downloadCardStatementsExcel();
      return;
    }
    if (file === 'availableDays') {
      this.downloadBoatFile(QueueDownloadUrl.AvailableDays);
    }
    if (file === 'chartersList') {
      this.downloadCharterList();
      return;
    }
    if (file === 'charterTemplate') {
      this.downloadCharterTemplate();
      return;
    }
    if (file === 'fleetCharters') {
      this.downloadFleetChartersList();
      return;
    }
    if (file === 'charterData') {
      this.downloadCharterData();
      return;
    }
    if (file === 'tasksList') {
      this.downloadQueueFile(QueueDownloadUrl.TasksList, this.filter);
      return;
    }
    if (file === 'tasksReport') {
      this.downloadQueueFile(QueueDownloadUrl.TasksReport, this.filter);
      return;
    }
    if (file === 'inventoryList') {
      this.downloadQueueFile(QueueDownloadUrl.InventoryList, this.filter);
      return;
    }
    if (file === 'workListReport') {
      this.downloadWorkListReport();
      return;
    }
    if (file === 'checklistTemplate') {
      this.downloadFile(DownloadUrl.ChecklistTemplate, {boat: this.boatId});
      return;
    }
    if (file === 'checklist') {
      this.downloadQueueFile(QueueDownloadUrl.Checklist, this.filter);
      return;
    }
    if (file === 'inventoryTemplate') {
      this.downloadFile(DownloadUrl.InventoryTemplate, {boat: this.boatId});
      return;
    }
    if (file === 'inventoryUsefulData') {
      this.downloadFile(DownloadUrl.InventoryUsefulData, {boat: this.boatId});
      return;
    }
    if (file === 'fleetTasksList') {
      this.downloadQueueFile(QueueDownloadUrl.FleetTasksList, this.filter);
      return;
    }
    if (file === 'bankAccountStatements') {
      this.downloadQueueFile(QueueDownloadUrl.BankAccountStatements, this.filter);
      return;
    }
  }

  private downloadLocalFile(): void {
    const file = this.file;
    this.fileDownloader.saveFile(file, file.name, file.type);
  }

  downloadExpenseTemplate(): void {
    const boatId = this.boatIdParam;
    this.downloadFile(DownloadUrl.ExpenseTemplate, {boatId});
  }

  downloadExpenseReport(): void {
    this.downloadQueueFile(QueueDownloadUrl.ExpenseReport, this.downloadFilter);
  }

  downloadCrewList(): void {
    const boatId = this.boatIdParam;
    this.downloadQueueFile(QueueDownloadUrl.CrewList, {boatId});
  }

  downloadBoatUserList(): void {
    const boatId = this.boatIdParam;
    this.downloadFile(DownloadUrl.BoatUserList, {boatId});
  }

  downloadExpenseReceipts(): void {
    this.downloadFile(DownloadUrl.ExpenseReceipts, this.downloadFilter);
  }

  downloadBoatFile(url: QueueDownloadUrl): void {
    const boatId = this.boatDetailsService.boatId;
    const filter = this.filter;
    const payload = {boatId, ...filter};
    this.downloadQueueFile(url, payload);
  }

  downloadMyCv(): void {
    this.downloadQueueFile(QueueDownloadUrl.MyCv, {});
  }

  downloadCrewCv(): void {
    const boatId = this.boatDetailsService.boatId;
    const filter = this.filter;
    const payload = {boatId, ...filter};
    this.downloadQueueFile(QueueDownloadUrl.CrewCv, payload);
  }

  downloadByUrl(url: DownloadUrl): void {
    const params = this.filter || {};
    this.downloadFile(url, params);
  }

  downloadCardStatementsPdf() {
    this.downloadQueueFile(QueueDownloadUrl.CardStatementsPdfReport, this.downloadFilter);
  }

  downloadCardStatementsExcel() {
    this.downloadQueueFile(QueueDownloadUrl.CardStatementsExcelReport, this.downloadFilter);
  }

  downloadCharterTemplate(): void {
    const boatId = this.boatDetailsService.boatId;
    this.downloadFile(DownloadUrl.CharterImportListTemplate, {boatId});
  }

  downloadCharterList(): void {
    const boatId = this.boatDetailsService.boatId;
    const filter = this.filter;
    const payload = {boatId, ...filter};
    this.downloadQueueFile(QueueDownloadUrl.CharterList, payload);
  }

  downloadFleetChartersList(): void {
    const fleet = this.fleetId;
    const filter = this.filter;
    const payload = {fleet, ...filter};
    this.downloadQueueFile(QueueDownloadUrl.FleetChartersList, payload);
  }

  downloadCharterData(): void {
    this.downloadQueueFile(QueueDownloadUrl.CharterData, this.filter);
  }

  downloadWorkListReport(): void {
    this.downloadQueueFile(QueueDownloadUrl.WorkList, {...this.filter, boat: this.boatIdParam});
  }

  downloadFile(url: string, payload: any, buildParams = false): void {
    this.fileDownloader.downloadFileByUrl(url, payload, buildParams).pipe(
      takeUntil(this.destroyStream$),
    ).subscribe((res: HttpResponse<Blob>) => {
      this.emitProgress();
      this.fileDownloader.parseAndSaveFile(res);
    });
  }

  downloadQueueFile(url: string, payload: any): void {
    this.queueFileDownloaderService.downloadQueueFileByUrl(url, payload).pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(_ => this.emitProgress());
  }

  private emitProgress(): void {
    this.downloadStart.emit();
  }

}
