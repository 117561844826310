import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';

import {CustomOverlayRef} from '../../../../modules/modal';

@Component({
  selector: 'app-added-file-to-queue-modal',
  templateUrl: './added-file-to-queue-modal.component.html',
  styleUrls: ['./added-file-to-queue-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddedFileToQueueModalComponent {

  constructor(
    @Inject(CustomOverlayRef) private readonly overlayRef: CustomOverlayRef,
  ) {
  }

  onApply(): void {
    this.closeDialog(true);
  }

  onCancel(): void {
    this.closeDialog(null);
  }

  private closeDialog(event: boolean | null): void {
    this.overlayRef.close(event);
  }
}
