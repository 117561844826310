import {inject, Injectable} from '@angular/core';
import {cleanObject} from '@idapgroup/js-object-utils';
import {loader} from '@modules/custom-loader/models/decorators';
import {EMPTY, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {FeatureToggleService} from '@core/modules/feature-toggle';
import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {buildURLParams} from '@helpers/build-url-params';
import {ResponseDto} from '@models/response';

import {FileDownloaderService} from './file-downloader.service';

import {AddedFileToQueueModalService} from '../added-file-to-queue-modal/services/added-file-to-queue-modal.service';
import {DownloadQueueResponseDto} from '../models/download-files.model';

@Injectable()
export class QueueFileDownloaderService {

  private readonly addedFileToQueueModalService = inject(AddedFileToQueueModalService);

  constructor(
    private readonly http: TransferHttpService,
    private readonly fileDownloaderService: FileDownloaderService,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  downloadQueueFileByUrl<T extends Record<string, any>>(url: string, payload: T): Observable<DownloadQueueResponseDto> {
    const isFeatureEnabled = this.featureToggleService.isFeatureEnabled('queueDownload');
    if (isFeatureEnabled) {
      return this.getDownloadQueueFile(url, payload)
        .pipe(
          switchMap(data => {
            if (data) {
              return this.addedFileToQueueModalService.openSuccessAddToQueueModal().pipe(map(() => data));
            }
            return EMPTY;
          }),
        );
    }
    const oldUrl = url.split('-').filter(segment => segment !== 'queue').join('-');
    return this.fileDownloaderService.downloadFileByUrl(oldUrl, payload).pipe(
      switchMap(res => of(this.fileDownloaderService.parseAndSaveFile(res))),
      map(() => ({queueId: new Date().getTime()})),
    );
  }

  @loader(true)
  private getDownloadQueueFile<T extends Record<string, any>>(url: string, payload: T): Observable<DownloadQueueResponseDto> {
    const params = buildURLParams(cleanObject(payload));
    return this.http.get<ResponseDto<DownloadQueueResponseDto>>(url, {params})
      .pipe(
        map(res => res.data),
      );
  }
}
