import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MODAL_DATA} from '@modules/modal/classes/modal-data';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent implements OnInit {

  constructor(@Inject(MODAL_DATA) public readonly data: {src: string, name: string}) {
  }

  ngOnInit() {
  }

}
