<div class="download-notification" *transloco="let t; read: 'download'">
  <h3 class="download-notification__title title-h3">
    {{ t('modal.addTitle') }}
  </h3>
  <div class="download-notification__content">
    <p class="download-notification__text">
      {{ t('modal.message') }}
    </p>
    <p class="download-notification__text">
      {{ t('modal.sMessage') }}
    </p>
  </div>
  <div class="download-notification__controls">
    <button class="seaz__btn_rounded seaz__btn_rounded--outline" (click)="onCancel()">
      {{ 'actions.close' | transloco }}
    </button>
    <button class="seaz__btn_primary seaz__btn_rounded" (click)="onApply()">
      {{ t('actions.open') }}
    </button>
  </div>
</div>
