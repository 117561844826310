import {Directive, ElementRef, HostBinding, HostListener, inject} from '@angular/core';

import {ImagePreviewService} from '../services/image-preview/image-preview.service';

@Directive({
  selector: '[appImagePreview]',
})
export class ImagePreviewDirective {

  @HostBinding('style.cursor') get cursor() {
    return 'pointer';
  };

  private readonly imagePreviewService = inject(ImagePreviewService);

  private get imageSource(): string | null {
    return this.elRef?.nativeElement?.src || null;
  }

  constructor(
    private readonly elRef: ElementRef<HTMLImageElement>,
  ) { }

  @HostListener('click', ['$event'])
  onClick(e: Event): void {
    e.stopPropagation();
    e.stopPropagation();
    const imageSource = this.imageSource;
    if (!imageSource) {
      return;
    }
    this.imagePreviewService.showImage(imageSource);
    // this.openModal();
  }

}
