import {inject, Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {AppRouterService} from '@core/services';

import {OverlayService} from '../../../../modules/modal';
import {AddedFileToQueueModalComponent} from '../components/added-file-to-queue-modal.component';

@Injectable()
export class AddedFileToQueueModalService {

  private readonly overlayService = inject(OverlayService);
  private readonly appRouterService = inject(AppRouterService);

  constructor() { }

  openSuccessAddToQueueModal(): Observable<boolean | null> {
    const ref = this.overlayService.open(
      AddedFileToQueueModalComponent,
      null,
      {
        panelClass: ['modal-small', 'is-active'],
        backdropClass: 'modal-background',
      },
    );
    return ref ? ref.afterClosed$.pipe(
      map(event => event.data),
      tap(res => {
        if (res) {
          this.appRouterService.navigateToDownloads();
        }
      }),
    ) : EMPTY;
  }
}
