export enum MediaUploadStorageUrls {
  EXPENSE_RECEIPT = 'origin_expense_receipt',
  BOAT_CONTRACT = 'origin/boat_contract',
  BOAT_CONTRACT_REFERENCE = 'origin/boat_contract_reference',
  BOAT_DOCUMENT = 'origin/boat_document',
  BOAT_FINANCE = 'origin/boat_finance',
  BOAT_IMAGE = 'origin/boat_image',
  BOAT_LOGO = 'origin/boat_logo',
  COUNTRY_FLAG = 'origin/country_flag',
  CV_BANK_DETAIL = 'origin/cv_bank_detail',
  SUPPLIER_BANK_DETAIL = 'origin/supplier_bank_detail',
  CV_DOCUMENT = 'origin/cv_document',
  CV_YACHT_EXPERIENCE = 'origin/cv_yacht_experience',
  CV_YACHT_EXPERIENCE_REFERENCE = 'origin/cv_yacht_experience_reference',
  POSITION_IMAGE = 'origin/position_image',
  TMP = 'origin/tmp',
  USER_ADDITIONAL_PHOTO = 'origin/user_additional_photo',
  USER_AVATAR = 'origin/user_avatar',
  USER_COURSE = 'origin/user_course',
  USER_COVER_CUSTOM = 'origin/user_cover_custom',
  USER_VIDEO = 'origin/user_video',
  SUPPLIER_GENERAL_FILES = 'origin/supplier_general',
  COMPANY_LOGO = 'origin/company_logo',
  CHARTERS_ATTACHMENTS = 'origin/charter_attachment',
  GUEST_ATTACHMENT = 'origin/guest_attachment',
  GUEST_IMAGE = 'origin/guest_image',
  APA_RECEIPT = 'origin/apa_receipt',
  BUDGET_COMMENT = 'origin/budget_comment',
  BOAT_SUPPLIER_BANK_DETAILS = 'origin/boat_supplier_bank_detail',
  SUPPLIER_DOCUMENT = 'origin/supplier_document',
  FLEET_IMAGE = 'origin/fleet_image',
  BOAT_EQUIPMENT = 'origin/boat_equipment',
  BOAT_INVENTORY = 'origin/boat_inventory',
  BOAT_TASK_NOTES = 'origin/boat_task_note',
}

export interface FileMultipartI {
  form: MultipartDataFormI;
  fileName: string;
  path: string;
}

interface MultipartDataFormI {
  formInputs: MultipartInputI;
  formAttributes: MultipartAttributesI;
}

interface MultipartInputI {
  'Content-Type': string;
  acl: string;
  key: string;
  'X-Amz-Credential': string;
  'X-Amz-Algorithm': string;
  'X-Amz-Date': string;
  Policy: string;
  'X-Amz-Signature': string;
}

interface MultipartAttributesI {
  'action': string;
  'method': string;
  'enctype': string;
}

export class MediaUploadDto {
  constructor(
    public readonly url: string,
    public readonly fileName: string,
  ) {
  }
}

export class MediaParams {
  constructor(
    public readonly path: MediaUploadStorageUrls,
    public readonly ext: string,
  ) {
  }
}

export class MediaMultipartParams {
  readonly policy: string;
  readonly algorithm: string;
  readonly credential: string;
  readonly date: string;
  readonly signature: string;
  readonly key: string;
  readonly action: string;
  readonly contentType: string;
  readonly acl: string;

  constructor(
    data: FileMultipartI,
  ) {
    this.policy = data.form.formInputs.Policy;
    this.algorithm = data.form.formInputs['X-Amz-Algorithm'];
    this.credential = data.form.formInputs['X-Amz-Credential'];
    this.date = data.form.formInputs['X-Amz-Date'];
    this.signature = data.form.formInputs['X-Amz-Signature'];
    this.key = `${data.path}/${data.fileName}`;
    this.action = data.form.formAttributes.action;
    this.contentType = data.form.formInputs['Content-Type'];
    this.acl = data.form.formInputs.acl;
  }
}

export class HttpMediaResponseDto {
  constructor(
    public readonly file: Blob | null,
    public readonly downloadUrl: string,
  ) {
  }
}

export type  MediaUploadProgressType = 'pending' | 'in-progress' | 'done';

export interface MediaUploadProgress {
  progress: number;
  state: MediaUploadProgressType;
}

export interface TotalMediaUploadProgress extends MediaUploadProgress {
  totalSize: number;
  isAllUploaded: boolean;
}

export interface MediaUploadFileProgress {
  progress: number;
  fileSize: number;
}

export interface MediaPreSignedUrlDto {
  s3Url: string;
  extension: string;
}

export interface MediaPreSignedPayload {
  key: string;
}
