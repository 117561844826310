import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ImagePreviewComponent} from './components/image-preview/image-preview.component';
import {ImagePreviewDirective} from './directives/image-preview.directive';
import {ImagePreviewService} from './services/image-preview/image-preview.service';

import {ModalModule} from '../../modules/modal';

@NgModule({
  declarations: [ImagePreviewComponent, ImagePreviewDirective],
  imports: [
    CommonModule,
    OverlayModule,
    ModalModule,
  ],
  providers: [ImagePreviewService],
  exports: [ImagePreviewDirective],
})
export class ImagePreviewModule { }
