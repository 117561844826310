import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {ComponentRef, Injectable, Injector} from '@angular/core';

import {OverlayService} from '../../../../modules/modal/services/overlay.service';
import {ImagePreviewComponent} from '../../components/image-preview/image-preview.component';
import {DEFAULT_IMAGE_PREVIEW_CONFIG, IMAGE_PREVIEW_DIALOG_DATA, ImagePreviewDialogConfig} from '../../models';
import {ImagePreviewOverlayRef} from '../image-preview-overlay-ref/image-preview-overlay-ref.service';

@Injectable()
export class ImagePreviewService {

  constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
    private readonly overlayService: OverlayService,
  ) {
  }

  showImage(src: string, name: string = ''): void {
    this.overlayService.open(ImagePreviewComponent, {src, name}, {
      panelClass: ['modal-small', 'modal-transparent', 'is-active'],
    });
  }

  open(config: ImagePreviewDialogConfig = {}) {
    // Override default configuration
    const dialogConfig = {...DEFAULT_IMAGE_PREVIEW_CONFIG, ...config};

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new ImagePreviewOverlayRef(overlayRef);

    this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: ImagePreviewDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: ImagePreviewDialogConfig, dialogRef: ImagePreviewOverlayRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(ImagePreviewComponent, null, injector);
    const containerRef: ComponentRef<ImagePreviewComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: ImagePreviewDialogConfig, dialogRef: ImagePreviewOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ImagePreviewOverlayRef, dialogRef);
    injectionTokens.set(IMAGE_PREVIEW_DIALOG_DATA, config.image);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: ImagePreviewDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });
  }
}
